<template>
<div class="admin-container">
  <router-view></router-view>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

@import '../../assets/styleVars.scss';

.admin-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>